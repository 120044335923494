import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "/src/components/layout"


export default function Example() {
    return (
        <Layout>
            <div className="relative bg-white">
                <div className="lg:absolute lg:inset-0">
                    <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
                        <StaticImage
                            className="h-56 w-full object-cover lg:absolute lg:h-full filter saturate-75"
                            src="../images/calves-in-middle-field.jpg"
                            alt="Cattle eating grass in a field"
                            transformOptions={{ fit: "cover", cropFocus: "entropy" }}
                        />
                        <div className="absolute inset-0 bg-gradient-to-r from-brand-dark to-brand-light mix-blend-multiply opacity-5" />
                    </div>
                </div>
                <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
                    <div className="lg:col-start-2 lg:pl-8">
                        <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                            <h2 className="leading-6 text-blue-600 font-semibold tracking-wide uppercase">Work with us</h2>
                            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                About Us
                            </h3>
                            <p className="mt-8 text-lg text-gray-500">
                              Jon Christopher has been involved with agriculture from an early age. Jon was brought up on a dairy farm in rural Herefordshire, where calf rearing was part of this. At the time, all the dairy herd replacements were home reared in small group pens on whole milk.
                            </p>
                            <div className="mt-5 prose prose-indigo text-gray-500">
                                <p>
                                In 2013 a local supply of Hereford cross calves became available. These calves arrived in small groups, and they were reared on powdered milk in pens of five on Wye Dale feeders. Over time, other farming and contracting commitments made it more difficult to keep feeding times consistent, and so the decision was made to purchase a Holm and Laue HL100 automatic feeder. This helped to ensure calves were fed on time and to take advantage of breaking feeds into smaller, more frequent ones.
                                </p>
                                <p>
                                After assisting with the installion of the calf feeder at the farm, the opportunity arose to start installing new machines for other farmers. The feeder service and repair business began! It has now grown to the point where we are pleased to have gained our own dealer area and will be able to provide our customers with continued reliable service and the latest in calf feeding technology from Holm & Laue. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
